<template>
  <div id="app" class="center">
    <Formatter/>
  </div>
</template>

<script>
import Formatter from "@/components/Formatter";

export default {
  name: 'App',
  components: {
    Formatter
  }
}
</script>

<style>
#app {
  margin-top: 50px;
}
* {
  font-family: "Microsoft YaHei UI", "Segoe UI", Arial, sans-serif;
  font-size: large;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
}

</style>

<template>
  <div id="two-way-binding" class="flex-container">
<!--    <div class="flex-child center nowrap">-->
<!--      换行 = 跳到下一行 <br>-->
<!--      空一行 = 跳到下一页-->
<!--    </div>-->
<!--    <hr>-->
    <div class="flex-child center nowrap">
      每行字数*每页行数
      <button v-for="preset in presets"
              v-bind:key="preset"
              v-on:click="cols = preset[0]; rows = preset[1];"
              v-bind:class="{ active: cols === preset[0] && rows === preset[1]}">
        {{ preset.join('*') }}
      </button>
      <!--    每行字数：<input type="number" style="width: 2em" v-model="cols" disabled /> <br>-->
      <!--    每页行数：<input type="number" style="width: 2em" v-model="rows" disabled /> <br>-->
      <br><br>
      <textarea cols="60" rows="30" spellcheck="false" v-model="message" v-on:input="copyResultStatus = null" />
    </div>
    <hr>
    <div class="flex-child center nowrap" style="width: 100%">
      <button type="button" v-on:click="copyPreview">
        {{ copyResultStatus ?? "复制到剪贴板" }}
      </button>
      <br><br>
      <div class="preview">
        <div class="preview-page" v-for="(page, i) in preview" v-bind:key="i" style="position: relative">
          {{ i + 1 }}
          <div style="height: 1em"></div>
          <div style="position: absolute; border-left: 1px solid lightgray; border-top: 1px solid lightgray">
            <div v-for="j in rows" v-bind:key="j">
              <div class="char-box" v-for="k in cols" v-bind:key="k"><div>&#12288;</div></div>
            </div>
          </div>
          <div style="position: absolute; margin-left: 0.125em; margin-top: 1px">{{ page }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {copyText} from 'vue3-clipboard';

const space = '\u3000';
const invisible = '\u200b';

function toFullWidth(txt) {
  let ans = '';
  const newLine = '\n'.codePointAt(0);
  for (let i = 0; i < txt.length; i++) {
    const code = txt.charCodeAt(i);
    if (code === 32) {
      ans += space;
    } else if (code < 127 && code !== newLine) {
      ans += String.fromCharCode(code + 65248);
    } else {
      ans += txt[i];
    }
  }
  return ans;
}

function wrap(txt, cols) {
  let ans = '';
  let width = 0;
  for (let i = 0; i < txt.length; i++) {
    if (txt[i] === '\n') {
      if (txt[i - 1] !== '\n') {
        while (width++ < cols) {
          ans += space;
        }
      }
      width = 0;
    } else if (width < cols) {
      width++;
    } else {
      width = 1;
      ans += '\n';
    }
    ans += txt[i];
  }
  return ans;
}

function paginate(txt, rows) {
  let ans = "";
  let height = 1;
  for (let i = 0; i < txt.length; i++) {
    if (txt[i] === '\n') {
      if (ans[ans.length - 1] === '\n') {
        height = 1;
      } else if (height < rows) {
        height++;
      } else {
        height = 1;
        ans += '\n'
      }
    }
    ans += txt[i];
  }
  return ans.replace(/\n{3,}/g, '\n\n');
}

function formatText(txt, cols, rows) {
  txt = toFullWidth(txt);
  txt = wrap(txt, cols);
  txt = paginate(txt, rows);
  txt = txt.split(invisible).join(space);
  return txt;
}

export default {
  data() {
    return {
      message: '',
      cols: 8,
      rows: 2,
      copyResultStatus: null,
      presets: [[6, 2], [6, 3], [8, 2], [8, 4], [9, 7]],
    }
  },
  computed: {
    result() {
      return formatText(this.message, this.cols, this.rows);
    },
    preview() {
      if (this.result === '') {
        return [];
      } else {
        return this.result.split('\n\n');
      }
    },
    preset: {
      get: function() {
        return this.cols.toString() + '*' + this.rows.toString();
      },
      set: function(value) {
        if (value) {
          const colsAndRows = value.split('*');
          if (colsAndRows.length >= 2) {
            this.cols = parseInt(colsAndRows[0]);
            this.rows = parseInt(colsAndRows[1]);
          }
        }
      }
    }
  },
  methods: {
    copyPreview() {
      let result = this.result;
      result = result.replace(/(?<!\n)\n(?!\n)/g, '');
      result = result.split(space).join(invisible);
      copyText(result, undefined, error => {
        if (error) {
          this.copyResultStatus = error;
        } else {
          this.copyResultStatus = "复制成功！";
        }
      })
    }
  }
}
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}
.flex-container {
  display: flex;
}
.flex-child {
  display: inline-block;
  margin: 20px;
}
.active {
  color: blue;
  border-color: blue;
}
.preview {
  display: flex;
  flex-wrap: wrap;
}
/*noinspection CssInvalidFunction*/
.preview-page {
  letter-spacing: 0.25em;
  line-height: 1.3em;
  width: calc(v-bind(cols) * 1.25em);
  height: calc(v-bind(rows) * 1.25em + 3em);
  margin: 0 5px 10px 5px;
  padding: 8px;
  border: 1px solid gray;
  white-space: pre;
  display: inline-block;
  break-inside: avoid;
}
.char-box {
  user-select: none;
  z-index: -1;
  display: inline-block;
  margin: 0 0 -1px -1px;
  padding: 0;
  width: fit-content;
  height: fit-content;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  color: transparent
}
</style>